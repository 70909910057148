<template>
   <YLayoutBodyMenu :menu="[
       {title:'规则配置',path:'/shop/plugin/points-mall/conf'},
       {title:'商品管理',path:'/shop/plugin/points-mall/goods'},
       {title:'兑换订单',path:'/shop/plugin/points-mall/order'},
       {title:'用户管理',path:'/shop/plugin/points-mall/user'},
   ]">
     <router-view/>
   </YLayoutBodyMenu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";
export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>